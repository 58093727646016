<template>
  <div>
      <button @click="test">test</button>
  </div>
</template>

<script>
import moment from 'moment'

// import { downloadFile } from "@/api/contract";
// import { saveAs } from "file-saver";
import { encode } from "js-base64";


export default {


  methods: {

        // 金慧的文件名称格式化
    fileNameFormatter(path) {
      const arr = path.split("_");
      if (arr.length >= 2) {
        return arr[1];
      } else {
        return path;
      }
    },

    test() {
      const path = '254976_4.2-2020823S-深汕特别合作区省定贫困村生活污水处理终端建设示范项目EPC总承包.pdf_111270356';
            const num = path.split("_")[0];
        
      const time = moment().format("YYYY-MM-DD HH:mm:ss");
      const auth = encode(num + "_" + time);
      const url = `http://218.94.135.30:8025/FileStore/Download.aspx?auth=${auth}`;

      window.open(url)
      const that = this;
      this.$info({
        title: '如果浏览器无法下载，请手动复制下方文件链接',
        content: url,
        okText: '复制',
        onOk() {
          that.$copy(url)
        }
      })

      // downloadFile({
      //   fileUrl: url
      // })
      //   .then(blob => {
      //     saveAs(blob, (path));
      //   })
      //   .catch(err => {
      //   console.log('err',err)
      // })
      //   .finaly(() => {
      //     hide();
      //   });

    }
  }
};
</script>